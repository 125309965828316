<template>
  <v-container class="shop-at-work">
    <v-row v-if="isAuthenticated">
      <div class="title col-md-12 col-sm-12 col-xs-12">
        <h1><b v-html="category.metaData.category_info.TITLE"></b></h1>
      </div>
      <div class="subtitle">
        <span v-html="category.metaData.category_info.DESCRIPTION"></span>
      </div>
      <div v-if="category.proposals['header-banner'] && !isMobile">
        <div
          v-for="proposal in category.proposals['header-banner']"
          v-bind:key="proposal.proposalId"
          class="background-temp"
        >
          <img :src="proposal.img" alt="Proposta" />
        </div>
      </div>
      <div v-if="category.proposals['footer-banner'] && isMobile">
        <div
          v-for="proposal in category.proposals['footer-banner']"
          v-bind:key="proposal.proposalId"
          class="background-temp"
        >
          <img :src="proposal.img" alt="Proposta" />
        </div>
      </div>
    </v-row>
    <v-row v-if="isAuthenticated">
      <div class="form-wrapper">
        <div class="message-content content" v-if="!responseOk">
          <h1><b v-html="category.description"></b></h1>
        </div>

        <div class="register-company" v-if="!responseOk">
          <v-form ref="form" v-model="isValid" class="d-flex">
            <v-text-field
              v-model="companyCode"
              color="secondary"
              :label="$t('shopAtWork.inputText')"
              outlined
              dense
              :rules="[requiredRules()]"
              required
            ></v-text-field>
            <v-btn
              class="secondary subscribe-button"
              height="40"
              @click="subscribeCompany(companyCode)"
              large
              depressed
              >{{ $t("shopAtWork.subscribeBtn") }}</v-btn
            >
          </v-form>
        </div>
        <div v-if="responseOk" class="message-wrapper">
          <h1 class="message-title">
            <b>{{ message.title }}</b>
          </h1>
          <span class="message-descr">{{ message.descr }}</span>
          <button
            @click="openAddressSelector()"
            class="btn btn-primary select-btn"
          >
            Apri
          </button>
        </div>
      </div>
    </v-row>
    <div class="row faq" v-if="isAuthenticated">
      <div class="faq-wrapper">
        <div class="message-content content">
          <h1><b>Come funziona?</b></h1>
        </div>

        <div
          v-for="media in orderedMedia"
          class="faq-box col-xs-12"
          v-bind:key="media.mediaId"
        >
          <div class="faq-img col-xs-12 col-md-6 col-lg-5">
            <img :src="media.url" alt="Media FAQ" />
          </div>
          <div class="faq-descr col-xs-12 col-md-6 col-lg-7">
            <h1 class="faq-title">
              <b>{{ media.alt }}</b>
            </h1>
            <span class="faq-cnt">{{ media.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.shop-at-work-subscription {
  .v-text-field {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .v-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import { mapGetters } from "vuex";
import orderBy from "lodash/orderBy";

import DeliveryService from "~/service/deliveryService";

import { requiredValue } from "~/validator/validationRules";

import AnalyticsService from "~/service/analyticsService";

export default {
  name: "ShopAtWorkSubscription",
  mixins: [cartInfo, categoryMixins],
  data() {
    return {
      isValid: false,
      companyCode: null,
      requiredRules: requiredValue,
      responseOk: false,
      message: {}
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    }),
    orderedMedia() {
      let t = 0;
      this.category.media.forEach(media => {
        media.mediaId = t++;
      });
      return orderBy(this.category.media, ["mediaId"], ["desc"]);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    async subscribeCompany(companyCode) {
      if (this.$refs.form.validate()) {
        let res = await DeliveryService.subscribeCompany(companyCode);
        console.log(res);
        if (
          res &&
          res.shopatworkContracts &&
          res.shopatworkContracts.length > 0
        ) {
          this.responseOk = true;
          this.message = {
            title: "Il codice è stato inserito correttamente!",
            descr:
              "Apri la finestra di selezione del servizio per selezionare l'indirizzo aggiunto."
          };
          AnalyticsService.trackEvent(
            "Delivery",
            "Subscribe Company",
            companyCode
          );
        }
        // se ok, apro address selector
        // this.openAddressSelector();
      }
    },
    created() {
      this.companyCode = this.$route.params.code;
    }
  }
};
</script>
